/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Error from '../../components/Error/Error';
import DatePicker from '../../components/DatePicker/DatePicker';
import HistoryTable from './HistoryTable';
import { LinearProgress } from '@material-ui/core';
import { api } from '../../api/api';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  datePicker: {
    marginTop: theme.spacing(3),
  },
  apply: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function VerifiHistoryPage({ onRender }) {
  const classes = useStyles();
  const history = useHistory();

  const parsedQuery = queryString.parse(location.search);
  const initStartDate = moment().set({ hour: 0, minute: 0, second: 0 }).toISOString();
  const initEndDate = moment().set({ hour: 23, minute: 59, second: 59 }).toISOString();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [historyData, setHistoryData] = useState([]);

  const [applyButtonVisible, setApplyButtonVisible] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 25,
  });

  const [filters, setFilters] = useState({
    startDate: parsedQuery.startDate || initStartDate,
    endDate: parsedQuery.endDate || initEndDate,
  });

  // Effects
  useEffect(() => {
    onRender('Verifi History');
    loadHistory(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loadHistory(filters) {
    try {
      setLoading(true);
      const response = await api.get(
        `/verifi/history?startDate=${filters.startDate}&endDate=${filters.endDate}`
      );
      setHistoryData(response.data);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }

  function updateQueryParams() {
    const searchString = queryString.stringify({ startDate: filters.startDate, endDate: filters.endDate });

    history.push({
      search: searchString,
    });
  }

  function handlePaginationChange(newPagination) {
    setPagination(newPagination);
    loadHistory({
      ...newPagination,
      ...filters,
    });
  }

  function handleFilterChange(name, value) {
    const newFilters = { ...filters, [name]: value };
    const newPagination = {
      page: 0,
      limit: 25,
    };

    setFilters(newFilters);
    setPagination(newPagination);
    setApplyButtonVisible(true);
  }

  function applyFilters() {
    updateQueryParams();

    loadHistory({
      ...pagination,
      ...filters,
    });

    setApplyButtonVisible(false);
  }

  function renderPageContent() {
    if (error) {
      return <Error error={error} />;
    }

    if (loading) {
      return <LinearProgress />;
    }

    return (
      <HistoryTable data={historyData} pagination={pagination} onPaginationChange={handlePaginationChange} />
    );
  }

  return (
    <Container className={classes.container}>
      <DatePicker
        startDate={filters.startDate}
        endDate={filters.endDate}
        onStartDateChange={(date) => handleFilterChange('startDate', date)}
        onEndDateChange={(date) => handleFilterChange('endDate', date)}
      />

      {applyButtonVisible && (
        <Box className={classes.apply}>
          <Button
            variant='contained'
            color='primary'
            style={{
              width: '200px',
              marginLeft: '15px',
            }}
            onClick={() => applyFilters()}
          >
            Apply
          </Button>
        </Box>
      )}
      {renderPageContent()}
    </Container>
  );
}
