import React, { useState } from 'react';
import {
  Button,
  LinearProgress,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
} from '@material-ui/core';
import {
  FileCopy as FileCopyIcon,
  Delete as DeleteIcon,
  CheckCircle,
  Error as ErrorIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { api } from '../../api/api';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  card: {
    maxWidth: 345,
    margin: 'auto',
  },
  media: {
    height: 200,
  },
  progressBar: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  fileList: {
    marginTop: theme.spacing(2),
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  searchField: {
    flex: 1,
    marginRight: theme.spacing(1),
  },
  sectionHeader: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  sectionPaper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  copyIcon: {
    marginLeft: 'auto',
  },
}));

const ImageManagerComponent = () => {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState({});
  const [images, setImages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [copiedImageId, setCopiedImageId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState({ open: false, imageId: null });

  const handleFileChange = (event) => {
    setSelectedFiles(Array.from(event.target.files));
    setUploadResults({});
  };

  const handleUpload = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('images', file);
    });

    try {
      setError(null);
      setUploading(true);
      const response = await api.post('/cloudflare/upload', formData);
      const results = {};
      response.data.results.forEach((result) => {
        results[result.file] = result;
      });
      setUploadResults(results);
    } catch (uploadError) {
      console.error('Error uploading images:', uploadError);
      const results = {};
      selectedFiles.forEach((file) => {
        results[file.name] = { success: false, error: uploadError.message };
      });
      setUploadResults(results);
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveFailed = (fileName) => {
    setUploadResults((prev) => {
      const updatedResults = { ...prev };
      delete updatedResults[fileName];
      return updatedResults;
    });
    setSelectedFiles((prev) => prev.filter((file) => file.name !== fileName));
  };

  const handleSearch = async () => {
    try {
      setError(null);
      setLoading(true);
      const response = await api.get('/cloudflare/images', {
        params: { search: searchTerm },
      });
      setImages(response.data.images);
    } catch (error) {
      console.error('Error fetching images:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = (link, imageId) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopiedImageId(imageId);
        setTimeout(() => setCopiedImageId(null), 2000);
      })
      .catch((error) => {
        console.error('Error copying link:', error);
      });
  };

  const handleDelete = async (imageId) => {
    try {
      const response = await api.delete(`/cloudflare/images/${imageId}`);
      if (response.data.success) {
        setImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
        setUploadResults((prevResults) =>
          Object.fromEntries(
            Object.entries(prevResults).filter(([key, value]) => value.data?.result?.id !== imageId)
          )
        );
      } else {
        console.error('Error deleting image:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    } finally {
      setConfirmDelete({ open: false, imageId: null });
    }
  };

  const openConfirmDelete = (imageId) => {
    setConfirmDelete({ open: true, imageId });
  };

  const closeConfirmDelete = () => {
    setConfirmDelete({ open: false, imageId: null });
  };

  return (
    <div className={classes.container}>
      {/* Upload Section */}
      <Paper className={classes.sectionPaper}>
        <Typography variant='h6' className={classes.sectionHeader}>
          Upload Images
        </Typography>
        <input
          accept='image/*'
          className={classes.input}
          id='contained-button-file'
          multiple
          type='file'
          onChange={handleFileChange}
        />
        <label htmlFor='contained-button-file'>
          <Button variant='contained' color='primary' component='span'>
            Select Images
          </Button>
        </label>
        <Button
          variant='contained'
          color='secondary'
          onClick={handleUpload}
          disabled={uploading || selectedFiles.length === 0}
          style={{ marginLeft: 10 }}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </Button>
        {uploading && <LinearProgress className={classes.progressBar} />}
        {error && <Typography color='error'>Error uploading images: {error}</Typography>}
        <List className={classes.fileList}>
          {selectedFiles.map((file) => (
            <ListItem key={file.name}>
              <ListItemIcon>
                {uploadResults[file.name]?.success ? (
                  <Tooltip title='Upload successful'>
                    <CheckCircle style={{ color: 'green' }} />
                  </Tooltip>
                ) : uploadResults[file.name]?.error ? (
                  <Tooltip title={uploadResults[file.name].error}>
                    <ErrorIcon style={{ color: 'red' }} />
                  </Tooltip>
                ) : null}
              </ListItemIcon>
              <ListItemText primary={file.name} />
              {
                <IconButton onClick={() => handleRemoveFailed(file.name)}>
                  <CloseIcon />
                </IconButton>
              }
            </ListItem>
          ))}
        </List>
        <Grid container spacing={3} style={{ marginTop: 20 }}>
          {Object.values(uploadResults)
            .filter((result) => result.success)
            .map((result) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={result.data.result.id}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.media}
                    image={result.data.result.variants[0]}
                    title={result.data.result.filename}
                  />
                  <CardContent>
                    <Typography variant='body2' color='textSecondary' component='p'>
                      {result.data.result.id}
                    </Typography>
                  </CardContent>
                  <CardActions disableSpacing>
                    <Tooltip
                      title={copiedImageId === result.data.result.id ? 'Copied!' : 'Copy Link'}
                      placement='top'
                    >
                      <IconButton
                        aria-label='copy link'
                        onClick={() => handleCopy(result.data.result.variants[0], result.data.result.id)}
                        className={classes.copyButton}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete Image' placement='top'>
                      <IconButton
                        aria-label='delete image'
                        onClick={() => handleDelete(result.data.result.id)}
                      >
                        <DeleteIcon style={{ color: 'red' }} />
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Paper>

      {/* Search Section */}
      <Paper className={classes.sectionPaper}>
        <Typography variant='h6' className={classes.sectionHeader}>
          Search Images
        </Typography>
        <div className={classes.searchContainer}>
          <TextField
            label='Search'
            variant='outlined'
            className={classes.searchField}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(event) => event.key === 'Enter' && searchTerm.length >= 3 && handleSearch()}
            helperText='3 characters minimum'
          />
          <Button
            disabled={loading || searchTerm.length < 3}
            variant='contained'
            color='primary'
            onClick={handleSearch}
          >
            {loading ? 'Searching...' : 'Search'}
          </Button>
        </div>
        {error && <Typography color='error'>Error searching images: {error.message}</Typography>}
        {loading && <LinearProgress className={classes.progressBar} />}
        <Grid container spacing={3}>
          {images.map((image) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
              <Card className={classes.card}>
                <CardMedia className={classes.media} image={image.variants[0]} title={image.filename} />
                <CardContent>
                  <Typography variant='body2' color='textSecondary'>
                    {image.id}
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Tooltip title={copiedImageId === image.id ? 'Copied!' : 'Copy Link'}>
                    <IconButton
                      onClick={() => handleCopy(image.variants[0], image.id)}
                      className={classes.copyIcon}
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Delete Image'>
                    <IconButton onClick={() => openConfirmDelete(image.id)}>
                      <DeleteIcon style={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>

      {/* Confirmation Modal */}
      <Dialog open={confirmDelete.open} onClose={closeConfirmDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this image? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmDelete} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => handleDelete(confirmDelete.imageId)} color='secondary'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ImageManagerComponent;
