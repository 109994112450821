/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';

import ImageManagerComponent from './ImageManagerComponent';

export default function MediaLibraryPage({ onRender }) {
  // EFFECTS
  useEffect(() => {
    onRender('Media Library');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ImageManagerComponent />
    </Container>
  );
}
