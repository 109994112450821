import React, { useContext } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Context as UserContext } from '../../context/UserContext';

import logo from './logo.png';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    width: '75%',
  },
  logoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
  },
});

const menuLinks = [
  {
    name: 'Orders',
    path: '/',
  },
  {
    name: 'Offers',
    path: '/offers',
  },
  {
    name: 'Cross-sells',
    path: '/crossSells',
  },
  {
    name: 'Free products',
    path: '/freeProducts',
  },
  {
    name: 'Special offers',
    path: '/specialOffers',
  },
  {
    name: 'Reviews',
    path: '/reviews',
  },
  {
    name: 'Banners',
    path: '/banners',
  },
  {
    name: 'Proof Popups',
    path: '/proofPopups',
  },
  {
    name: 'Promos',
    path: '/promos',
  },
  {
    name: 'Websites',
    path: '/websites',
  },
  {
    name: 'URL Params',
    path: '/urlParams',
  },
  {
    name: 'Verifi History',
    path: '/verifiHistory',
  },
  {
    name: 'Decline Settings',
    path: '/declineSettings',
  },
  {
    name: 'Media Library',
    path: '/mediaLibrary',
  },
  {
    name: 'Listicles',
    path: '/listicles',
  },
];

export default function MainMenu({ isOpen, onSetOpen }) {
  const classes = useStyles();
  const history = useHistory();

  const {
    state: { user },
  } = useContext(UserContext);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    onSetOpen(open);
  };

  function navigateTo(path) {
    history.push(path);
  }

  const list = () => (
    <div
      className={clsx(classes.list)}
      role='presentation'
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className={classes.logoWrapper}>
        <img src={logo} alt='logo' className={classes.logo} />
        <small>launch.sticky.io</small>
      </div>
      <Divider />
      <List>
        {menuLinks.map((item, index) => (
          <ListItem key={index} button onClick={() => navigateTo(item.path)}>
            <ListItemText primary={item.name} />
          </ListItem>
        ))}
        {/* {user?.user.isSuperAdmin && (
					<ListItem button onClick={() => navigateTo('/paymentRouters')}>
						<ListItemText primary={'Payment Routers'} />
					</ListItem>
				)} */}
        {user?.user.isSuperAdmin && (
          <ListItem button onClick={() => navigateTo('/users')}>
            <ListItemText primary={'Users'} />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <Drawer anchor='left' open={isOpen} onClose={toggleDrawer(false)}>
      {list()}
    </Drawer>
  );
}
