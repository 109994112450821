import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Error from '../../components/Error/Error';
import Loading from '../../components/Loading/Loading';
import Modal from '../../components/Modal/Modal';
import { Context as SpecialOffersContext } from '../../context/SpecialOffersContext';
import { Context as SpecialOfferContext } from '../../context/SpecialOfferContext';
import { Context as UserContext } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(4),
  },
  topButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
}));

export default function OffersPage({ onRender }) {
  const classes = useStyles();
  const history = useHistory();

  // STATE
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [offerToDelete, setOfferToDelete] = useState(null);

  // CONTEXTS
  const {
    state: { user },
  } = useContext(UserContext);

  const {
    state: { specialOffers, loading: specialOffersLoading, error: specialOffersError },
    loadSpecialOffers,
  } = useContext(SpecialOffersContext);

  const {
    state: { specialOffer, error: specialOfferError },
    createSpecialOffer,
    deleteSpecialOffer,
  } = useContext(SpecialOfferContext);

  // EFFECTS
  useEffect(() => {
    if (user || specialOffer) {
      loadSpecialOffers();
    }

    onRender('Special offers');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, specialOffer]);

  function handleDeleteOffer(id) {
    setDeleteModalOpen(true);
    setOfferToDelete(id);
  }

  async function handleCreateSpecialOffer() {
    const newSpecialOffer = await createSpecialOffer();
    history.push(`/specialOffers/${newSpecialOffer._id}`);
  }

  if (specialOffersError || specialOfferError) {
    return <Error error={specialOffersError || specialOfferError} />;
  }

  if (specialOffersLoading) {
    return <Loading />;
  }

  if (!specialOffers.length) {
    return null;
  }

  return (
    <>
      <Modal open={isDeleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box>
          <Typography variant='h6' gutterBottom>
            Are you sure you want to delete this special offer?
          </Typography>
        </Box>
        <Box className={classes.modalButtons}>
          <Button
            variant='contained'
            color='primary'
            style={{ marginRight: '10px' }}
            onClick={async () => {
              setDeleteModalOpen(false);
            }}
          >
            No
          </Button>
          <Button
            variant='contained'
            color='secondary'
            onClick={async () => {
              await deleteSpecialOffer(offerToDelete);
              setDeleteModalOpen(false);
            }}
          >
            Yes
          </Button>
        </Box>
      </Modal>
      <Container className={classes.container}>
        <Box className={classes.topButtons}>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => handleCreateSpecialOffer()}
          >
            Add
          </Button>
        </Box>
        <TableContainer component={Paper} className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Template</TableCell>
                <TableCell align='right'>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {specialOffers.map((row) => (
                <TableRow key={row._id} hover>
                  <TableCell component='th' scope='row'>
                    <Link
                      component='button'
                      variant='body2'
                      onClick={() => {
                        history.push(`/specialOffers/${row._id}`);
                      }}
                    >
                      {row._id}
                    </Link>
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.template.name}</TableCell>
                  <TableCell align='right'>
                    <IconButton
                      color='secondary'
                      aria-label='Delete offer'
                      component='span'
                      className={classes.fbPixelDelete}
                      onClick={() => handleDeleteOffer(row._id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
