/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 750,
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const params = [
  {
    name: 'offerid',
    values: 'offer id from RA',
    note: 'Loads the offer with the specified ID.',
  },
  {
    name: 'promoid',
    values: 'promo id from RA',
    note: 'Loads the promo with the specified ID. Offerid is required.',
  },
  {
    name: 'oid',
    values: 'everflow offer id',
    note: 'Overrides everflow offer id from offer config.',
  },
  {
    name: 'preloader',
    values: '0,1',
    default: '1',
    note: 'Hides the preloader.',
  },
  {
    name: 'ndcrosssell',
    values: '0,1',
    default: '0',
    note: 'Converts cross-sell to new design.',
  },
  {
    name: 'countdown',
    values: '0,1',
    default: '1',
    note: 'Hides the countdown.',
  },
  {
    name: 'paypal',
    values: '0,1',
    default: '0',
    note: 'Adds PayPal as a payment option.',
  },
  {
    name: 'reviews',
    values: '0,1',
    default: '1',
    note: 'Hides the reviews.',
  },
  {
    name: 'olc',
    values: '0,1',
    default: '0',
    note: 'One line checkout.',
  },
  {
    name: 'opc',
    values: '0,1',
    default: '0',
    note: 'One product checkout.',
  },
  {
    name: 'skipmodal',
    values: '0,1',
    default: '0',
    note: 'Skips free products modal.',
  },
  {
    name: 'disclaimer',
    values: '0,1',
    default: '0',
    note: 'Shows web tv disclaimer.',
  },
];

export default function UrlParamsPage({ onRender }) {
  const classes = useStyles();

  // EFFECTS
  useEffect(() => {
    onRender('Url Params');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className={classes.container}>
        <TableContainer component={Paper} className={classes.paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Values</TableCell>
                <TableCell>Default</TableCell>
                <TableCell>Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {params.map((param, index) => (
                <TableRow key={index}>
                  <TableCell>{param.name}</TableCell>
                  <TableCell>{param.values}</TableCell>
                  <TableCell>{param.default}</TableCell>
                  <TableCell>{param.note}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
